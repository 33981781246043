<template>
  <div class="container">
    <div class="title">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211209172246311.png" />
    </div>
    <div class="record-list">
      <van-empty v-if="finished && datalist.length <= 0 " class="custom-image" image="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211210103825505.png" description="暂无中奖记录" />
      <template v-else>
        <div class="record-item" v-for="(item,index) in datalist" :key="index">
          <img :src="item.prizeImg" />
          <div class="git-cont">
            <div class="name">{{ item.prizeName }}</div>
            <div class="time">{{ item.dateTimes }}</div>
          </div>
          <div class="state">领取成功</div>
        </div>
      </template>
    </div>
    <van-button class="back-btn" type="default" round @click="back">返回抽奖</van-button>
  </div>
</template>

<script>
export default {
  name: 'SlotRecordList',
  data() {
    return {
      datalist: [],
      finished: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.get("/OrderGoods/Activity/GetGeneralprizeList", { isAll: 0 }, 2).then(json => {
        this.$toast.clear();
        this.finished = true;
        if (json.code === 1) {
          this.datalist = json.response;
        }
      });
    },
    back() {
      this.$router.back();
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  > img {
    width: 100%;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20211210174113533.png');
    background-size: 100% 100%;
  }
  .title {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 30;
    text-align: center;
    img {
      width: 290px;
    }
  }
}
.record-list {
  position: absolute;
  top: 50px;
  left: 22px;
  right: 22px;
  bottom: 60px;
  padding: 15px;
  overflow-y: auto;
  z-index: 10;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #deed53;
  .record-item {
    display: flex;
    padding: 15px 0;
    align-items: center;
    border-bottom: 1px solid #deed53;
    > img {
      width: 43px;
      height: 43px;
      margin-right: 10px;
    }
    .git-cont {
      width: calc(100% - 123px);
      .name {
        color: #000000;
        margin-bottom: 10px;
      }
      .time {
        color: #999999;
        font-size: 10px;
      }
    }
    .state {
      width: 70px;
      color: #008f37;
      text-align: right;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  /deep/ .van-empty__image {
    padding-top: 10%;
    width: 200px;
    height: auto;
  }
}
.back-btn {
  position: absolute;
  left: 15%;
  width: 70%;
  bottom: 10px;
  height: 40px;
  background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20211214102353543.png')
    no-repeat;
  background-size: 100% 100%;
  .van-button__text {
    color: #fff;
    font-size: 18px;
  }
}
</style>